import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Bubble from './bubble';
import useWindowWidth from '../hooks/useWindowWidth';

// Functions
import shuffleArray from '../helpers/shuffleArray';

// Styled Components
const BubbleContainer = styled.div`
  overflow: hidden;
  height: 635px;
  width: 100%;
  /* SHould match (or close) to canvas width in bubble */
  max-width: 1900px;
  margin: 0 auto;
`;

const BubblesDiv = styled.div`
  padding: ${({ theme }) => theme.spacing[2]};
  position: relative;
`;

// Constants
const bubbleSpecs = [
  { x: 1282, y: 335 },
  { x: 887, y: 327 },
  { x: 97, y: 307 },
  { x: 444, y: 303 },
  { x: -153, y: 234 },
  { x: 699, y: 198 },
  { x: 1098, y: 182 },
  { x: 242, y: 111 },
  { x: 493, y: 78 },
  { x: 1324, y: 66 },
  { x: 899, y: 57 },
  { x: 10, y: 50 },
];

const Bubbles = ({ memberData }) => {
  // const members = useStaticQuery(
  //   graphql`
  //     query {
  //       allSanityMember {
  //         nodes {
  //           companyName
  //           iconLogo {
  //             asset {
  //               fluid {
  //                 ...GatsbySanityImageFluid
  //               }
  //             }
  //           }
  //           iconBGColour {
  //             hex
  //           }
  //           logo {
  //             asset {
  //               fluid {
  //                 ...GatsbySanityImageFluid
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  const [shuffledMembers, setShuffledMembers] = React.useState(memberData);
  const windowWidth = useWindowWidth();

  React.useEffect(() => {
    setShuffledMembers(memberData);
  }, [memberData]);

  console.log('Bubbles Rendered');
  // console.log('Shuffling bubbles..');
  // const shuffledMembers = shuffleArray(memberData);

  return (
    <BubbleContainer>
      <BubblesDiv>
        {/* {members.allSanityMember.nodes.map((member, index) => ( */}
        {shuffledMembers.map((member, index) => (
          <Bubble
            key={member.companyName}
            member={member}
            specs={bubbleSpecs[index]}
            windowWidth={windowWidth}
          />
        ))}
      </BubblesDiv>
    </BubbleContainer>
  );
};

export default Bubbles;
