import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SimplexNoise from 'simplex-noise';
import Img from 'gatsby-image';

const BUBBLE_WIDTH = 200;

const BubbleWrap = styled.div`
  width: ${BUBBLE_WIDTH}px;
  height: ${BUBBLE_WIDTH}px;
  margin: ${({ theme }) => theme.spacing[2]};
  background: ${(props) => props.backgroundColor};
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.boxShadow.xl};
  border: 30px solid ${(props) => props.backgroundColor};
  /* z-index: 98; */
  display: block;

  span {
    position: absolute;
    will-change: transform, opacity;
    left: 50%;
    bottom: -80px;
    padding: 5px 12px;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadow.xl};
    border-radius: 50px;
    white-space: nowrap;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.secondary[200]};
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -10px);
    opacity: 0;
    transition: opacity, transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    z-index: 99;
  }
  &:hover {
    span {
      opacity: 0.9;
      transform: translate(-50%, 0);
    }
  }
`;

const BubbleDiv = styled(Img)`
  //This background image applies an opacity for some reason. Need to wrap.
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const SCROLL_SPEED = 0.3; // 0.3 default

const NOISE_SPEED = 0.012; // The frequency. Smaller for flat slopes, higher for jagged spikes.
const NOISE_AMOUNT = 0.1; // The amplitude. The amount the noise affects the movement.
const simplex = new SimplexNoise(Math.floor(Math.random() * 64000));

const Bubble = ({ member, specs: { x, y, s = 1 }, windowWidth }) => {
  // console.log(
  //   `Bubble rendered: ${member.companyName} ${member.iconBGColour &&
  //     member.iconBGColour.hex}`
  // );
  const [specs2, setSpecs2] = React.useState({ x, y, s, o: 1 });
  const [member2, setMember2] = React.useState(member);

  const CANVAS_WIDTH = Math.min(Math.max(windowWidth, 1440), 1850);
  const requestRef = React.useRef();
  const noiseSeedX = React.useRef(Math.floor(Math.random() * 64000));
  const noiseSeedY = React.useRef(Math.floor(Math.random() * 64000));
  const xWithNoise = React.useRef();
  const yWithNoise = React.useRef();
  const newO = React.useRef(1);

  const animate = (time) => {
    // Change state here to animate
    setSpecs2((prevState) => {
      noiseSeedX.current += NOISE_SPEED;
      noiseSeedY.current += NOISE_SPEED;

      const randomX = simplex.noise2D(noiseSeedX.current, 0);
      const randomY = simplex.noise2D(noiseSeedY.current, 0);

      let newX = prevState.x - SCROLL_SPEED;

      // Mobile & Regular desktop config
      if (windowWidth <= 1440) {
        // Allow slight margin to account for noise
        if (newX < -(BUBBLE_WIDTH + 30)) {
          newX = CANVAS_WIDTH;
        }
      }
      // large Desktop config (Change opacity)
      else {
        // If on left edge
        if (newX < 0.07 * CANVAS_WIDTH) {
          // Reduce Opacity to 0
          newO.current = newO.current < 0 ? 0 : newO.current - 0.02;
        }
        // Else if on right edge
        else if (
          newX > CANVAS_WIDTH - 2 * BUBBLE_WIDTH &&
          newX < CANVAS_WIDTH - BUBBLE_WIDTH
        ) {
          // Increase opacity
          // newO.current += 0.02;
          newO.current = newO.current > 1 ? 1 : newO.current + 0.02;
        }
        if (newX < -200) {
          newX = CANVAS_WIDTH - 0.8 * BUBBLE_WIDTH;
        }
      }
      // if (newX < 30 && windowWidth > 1440) {
      //   newO.current = newO.current < 0 ? 0 : newO.current - 0.02;
      // } else if (newX > CANVAS_WIDTH - BUBBLE_WIDTH) {
      //   newO.current += 0.02;
      // }

      xWithNoise.current = newX + randomX * NOISE_AMOUNT * 0.8;
      yWithNoise.current = prevState.y + randomY * NOISE_AMOUNT;

      return {
        ...prevState,
        x: xWithNoise.current,
        y: yWithNoise.current,
        o: newO.current,
      };
    });
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setMember2(member);
  }, [member]);

  return (
    <BubbleWrap
      key={member}
      backgroundColor={
        (member2.iconBGColour && member2.iconBGColour.hex) || `#FFFFFF`
      }
      style={{
        transform: `translate(${specs2.x}px, ${specs2.y}px) scale(${specs2.s})`,
        position: `absolute`,
        opacity: specs2.o,
      }}
    >
      <BubbleDiv
        fluid={
          (member2.iconLogo && member2.iconLogo.asset.fluid) ||
          (member2.logo && member2.logo.asset.fluid)
        }
        imgStyle={{
          objectFit: 'contain',
        }}
      />
      <span>{member2.companyName}</span>
    </BubbleWrap>
  );
};
export default Bubble;
